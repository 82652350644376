import api from './utilPartner';

const controller = 'PessoaDadoFinanceiroParceiro';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  LocalizarRegistroPorParceiroFaturamento: function (dto) {
    return api.Axios.GetParams(
      url + '/LocalizarRegistroPorParceiroFaturamento',
      dto
    );
  },

  LocalizarRegistroPorParceiroRecebiveis: function (dto) {
    return api.Axios.GetParams(
      url + '/LocalizarRegistroPorParceiroRecebiveis',
      dto
    );
  },

  ListarHistoricoFinanceiroPorParceiro: function (dto) {
    return api.Axios.GetParams(
      url + '/ListarHistoricoFinanceiroPorParceiro',
      dto
    );
  }
});
