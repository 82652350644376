import util from '@bmp-vue/core/api/util';
/* eslint-disable no-unused-vars */
const controller = 'Auxiliares';
export default Object.assign({}, util.GetCrud(controller, ['none']), {
  GetBanco: function () {
    return new Promise((resolve, reject) => {
      const array = [
        { id: 189, codigo: '654', descricao: 'Banco A. J. Renner S.A.' },
        { id: 9, codigo: '246', descricao: 'Banco ABC Brasil S.A.' },
        { id: 10, codigo: '075', descricao: 'Banco ABN AMRO S.A.' },
        { id: 115, codigo: '121', descricao: 'Banco Agibank S.A' },
        { id: 11, codigo: '025', descricao: 'Banco Alfa S.A.' },
        { id: 12, codigo: '641', descricao: 'Banco Alvorada S.A.' },
        { id: 13, codigo: '065', descricao: 'Banco Andbank (Brasil) S.A.' },
        { id: 176, codigo: '213', descricao: 'Banco Arbi S.A.' },
        { id: 14, codigo: '024', descricao: 'Banco BANDEPE S.A.' },
        { id: 15, codigo: '740', descricao: 'Banco Barclays S.A.' },
        { id: 16, codigo: '107', descricao: 'Banco BBM S.A.' },
        { id: 17, codigo: '096', descricao: 'Banco BM&FBOVESPA S.A' },
        { id: 18, codigo: '318', descricao: 'Banco BMG S.A.' },
        { id: 19, codigo: '752', descricao: 'Banco BNP Paribas Brasil S.A.' },
        {
          id: 20,
          codigo: '248',
          descricao: 'Banco Boavista Interatlântico S.A.'
        },
        {
          id: 21,
          codigo: '169',
          descricao: 'Banco Bonsucesso Consignado S.A.'
        },
        { id: 22, codigo: '218', descricao: 'Banco Bonsucesso S.A.' },
        { id: 23, codigo: '069', descricao: 'Banco BPN Brasil S.A.' },
        { id: 24, codigo: '063', descricao: 'Banco Bradescard S.A.' },
        { id: 25, codigo: '036', descricao: 'Banco Bradesco BBI S.A.' },
        { id: 142, codigo: '122', descricao: 'Banco Bradesco BERJ S.A.' },
        { id: 26, codigo: '204', descricao: 'Banco Bradesco Cartões S.A.' },
        {
          id: 27,
          codigo: '394',
          descricao: 'Banco Bradesco Financiamentos S.A.'
        },
        { id: 4, codigo: '237', descricao: 'Banco Bradesco S.A.' },
        { id: 28, codigo: '208', descricao: 'Banco BTG Pactual S.A.' },
        { id: 197, codigo: '336', descricao: 'Banco C6 S.A - C6 Bank' },
        { id: 29, codigo: '263', descricao: 'Banco Cacique S.A.' },
        { id: 30, codigo: '473', descricao: 'Banco Caixa Geral - Brasil S.A.' },
        { id: 182, codigo: '412', descricao: 'Banco Capital S. A.' },
        { id: 31, codigo: '040', descricao: 'Banco Cargill S.A.' },
        { id: 180, codigo: '266', descricao: 'Banco Cédula S.A.' },
        { id: 32, codigo: '739', descricao: 'Banco Cetelem S.A.' },
        { id: 33, codigo: '233', descricao: 'Banco Cifra S.A.' },
        { id: 34, codigo: '745', descricao: 'Banco Citibank S.A.' },
        { id: 177, codigo: '241', descricao: 'Banco Clássico S.A.' },
        {
          id: 35,
          codigo: '000',
          descricao: 'Banco CNH Industrial Capital S.A.'
        },
        { id: 36, codigo: '095', descricao: 'Banco Confidence de Câmbio S.A.' },
        {
          id: 37,
          codigo: '756',
          descricao: 'Banco Cooperativo do Brasil S.A. - BANCOOB'
        },
        { id: 38, codigo: '748', descricao: 'Banco Cooperativo Sicredi S.A.' },
        {
          id: 39,
          codigo: '222',
          descricao: 'Banco Credit Agricole Brasil S.A.'
        },
        {
          id: 40,
          codigo: '505',
          descricao: 'Banco Credit Suisse (Brasil) S.A.'
        },
        { id: 41, codigo: '003', descricao: 'Banco da Amazônia S.A.' },
        { id: 42, codigo: '083', descricao: 'Banco da China Brasil S.A.' },
        { id: 43, codigo: '707', descricao: 'Banco Daycoval S.A.' },
        { id: 181, codigo: '300', descricao: 'Banco de la Nacion Argentina' },
        {
          id: 184,
          codigo: '495',
          descricao: 'Banco de La Provincia de Buenos Aires'
        },
        {
          id: 183,
          codigo: '494',
          descricao: 'Banco de La Republica Oriental del Uruguay'
        },
        {
          id: 44,
          codigo: '000',
          descricao: 'Banco de Lage Landen Brasil S.A.'
        },
        {
          id: 45,
          codigo: '456',
          descricao: 'Banco de Tokyo-Mitsubishi UFJ Brasil S.A.'
        },
        { id: 1, codigo: '001', descricao: 'Banco do Brasil S.A.' },
        { id: 46, codigo: '047', descricao: 'Banco do Estado de Sergipe S.A.' },
        {
          id: 203,
          codigo: '032',
          descricao: 'BANCO DO ESTADO DO MATO GROSSO S/A'
        },
        { id: 47, codigo: '037', descricao: 'Banco do Estado do Pará S.A.' },
        {
          id: 48,
          codigo: '041',
          descricao: 'Banco do Estado do Rio Grande do Sul S.A.'
        },
        {
          id: 49,
          codigo: '004',
          descricao: 'Banco do Nordeste do Brasil S.A.'
        },
        { id: 50, codigo: '265', descricao: 'Banco Fator S.A.' },
        { id: 51, codigo: '224', descricao: 'Banco Fibra S.A.' },
        { id: 52, codigo: '626', descricao: 'Banco Ficsa S.A.' },
        { id: 53, codigo: '000', descricao: 'Banco Ford S.A.' },
        { id: 54, codigo: '000', descricao: 'Banco GMAC S.A.' },
        { id: 55, codigo: '612', descricao: 'Banco Guanabara S.A.' },
        { id: 56, codigo: '000', descricao: 'Banco Honda S.A.' },
        { id: 57, codigo: '000', descricao: 'Banco IBM S.A.' },
        {
          id: 58,
          codigo: '012',
          descricao: 'Banco INBURSA de Investimentos S.A.'
        },
        { id: 59, codigo: '604', descricao: 'Banco Industrial do Brasil S.A.' },
        { id: 60, codigo: '653', descricao: 'Banco Indusval S.A.' },
        { id: 187, codigo: '630', descricao: 'Banco Intercap S.A.' },
        { id: 61, codigo: '077', descricao: 'Banco Intermedium S.A.' },
        { id: 62, codigo: '249', descricao: 'Banco Investcred Unibanco S.A.' },
        { id: 63, codigo: '184', descricao: 'Banco Itaú BBA S.A.' },
        { id: 64, codigo: '029', descricao: 'Banco Itaú BMG Consignado S.A.' },
        { id: 65, codigo: '000', descricao: 'Banco Itaú Veículos S.A.' },
        { id: 66, codigo: '479', descricao: 'Banco ItauBank S.A' },
        { id: 67, codigo: '376', descricao: 'Banco J. P. Morgan S.A.' },
        { id: 68, codigo: '074', descricao: 'Banco J. Safra S.A.' },
        { id: 69, codigo: '217', descricao: 'Banco John Deere S.A.' },
        { id: 195, codigo: '757', descricao: 'Banco Keb Hana do Brasil S. A.' },
        { id: 70, codigo: '600', descricao: 'Banco Luso Brasileiro S.A.' },
        { id: 178, codigo: '243', descricao: 'Banco Máxima S.A.' },
        { id: 71, codigo: '389', descricao: 'Banco Mercantil do Brasil S.A.' },
        { id: 72, codigo: '370', descricao: 'Banco Mizuho do Brasil S.A.' },
        { id: 73, codigo: '746', descricao: 'Banco Modal S.A.' },
        { id: 125, codigo: '274', descricao: 'Banco Money Plus' },
        { id: 122, codigo: '735', descricao: 'Banco Neon S.A.' },
        { id: 74, codigo: '212', descricao: 'Banco Original S.A.' },
        { id: 190, codigo: '712', descricao: 'Banco Ourinvest S.A.' },
        { id: 75, codigo: '623', descricao: 'Banco PAN S.A.' },
        { id: 76, codigo: '611', descricao: 'Banco Paulista S.A.' },
        { id: 186, codigo: '613', descricao: 'Banco Pecúnia S. A.' },
        { id: 77, codigo: '094', descricao: 'Banco Petra S.A.' },
        { id: 78, codigo: '643', descricao: 'Banco Pine S.A.' },
        { id: 79, codigo: '000', descricao: 'Banco PSA Finance Brasil S.A.' },
        {
          id: 80,
          codigo: '747',
          descricao: 'Banco Rabobank International Brasil S.A.'
        },
        { id: 81, codigo: '633', descricao: 'Banco Rendimento S.A.' },
        { id: 191, codigo: '741', descricao: 'Banco Ribeirão Preto S.A.' },
        { id: 82, codigo: '120', descricao: 'Banco Rodobens S.A.' },
        { id: 5, codigo: '422', descricao: 'Banco Safra S.A.' },
        { id: 6, codigo: '033', descricao: 'Banco Santander (Brasil) S.A.' },
        { id: 192, codigo: '743', descricao: 'Banco Semear S.A.' },
        { id: 194, codigo: '754', descricao: 'Banco Sistema S.A.' },
        {
          id: 83,
          codigo: '366',
          descricao: 'Banco Société Générale Brasil S.A.'
        },
        { id: 188, codigo: '637', descricao: 'Banco Sofisa S. A.' },
        {
          id: 84,
          codigo: '464',
          descricao: 'Banco Sumitomo Mitsui Brasileiro S.A.'
        },
        { id: 85, codigo: '082', descricao: 'Banco Topázio S.A.' },
        { id: 86, codigo: '000', descricao: 'Banco Toyota do Brasil S.A.' },
        { id: 87, codigo: '634', descricao: 'Banco Triângulo S.A.' },
        { id: 88, codigo: '000', descricao: 'Banco Volkswagen S.A.' },
        { id: 89, codigo: '000', descricao: 'Banco Volvo Brasil S.A.' },
        { id: 90, codigo: '655', descricao: 'Banco Votorantim S.A.' },
        { id: 91, codigo: '610', descricao: 'Banco VR S.A.' },
        {
          id: 92,
          codigo: '119',
          descricao: 'Banco Western Union do Brasil S.A.'
        },
        {
          id: 143,
          codigo: '124',
          descricao: 'Banco Woori Bank do Brasil S.A.'
        },
        {
          id: 93,
          codigo: '021',
          descricao: 'BANESTES S.A. Banco do Estado do Espírito Santo'
        },
        {
          id: 94,
          codigo: '719',
          descricao: 'Banif-Banco Internacional do Funchal (Brasil)S.A.'
        },
        {
          id: 95,
          codigo: '755',
          descricao: 'Bank of America Merrill Lynch Banco Múltiplo S.A.'
        },
        {
          id: 96,
          codigo: '081',
          descricao: 'BBN Banco Brasileiro de Negócios S.A.'
        },
        {
          id: 97,
          codigo: '250',
          descricao: 'BCV - Banco de Crédito e Varejo S.A.'
        },
        {
          id: 99,
          codigo: '125',
          descricao: 'Brasil Plural S.A. - Banco Múltiplo'
        },
        { id: 100, codigo: '070', descricao: 'BRB - Banco de Brasília S.A.' },
        { id: 2, codigo: '104', descricao: 'Caixa Econômica Federal' },
        {
          id: 146,
          codigo: '130',
          descricao: 'Caruana S.A. Sociedade de Crédito'
        },
        {
          id: 140,
          codigo: '114',
          descricao:
            'Central das Coop. Econ. e Créd. Mútuo do Est do Esp. Santo'
        },
        {
          id: 130,
          codigo: '091',
          descricao:
            'Central de Coop. de Econ. e Créd. Mútuo do Est RS - Unicred'
        },
        {
          id: 101,
          codigo: '320',
          descricao: 'China Construction Bank (Brasil) Banco Múltiplo S.A.'
        },
        { id: 8, codigo: '477', descricao: 'Citibank N.A.' },
        {
          id: 169,
          codigo: '180',
          descricao: 'CM Capital Markets Corretora de Câmbio'
        },
        {
          id: 145,
          codigo: '127',
          descricao: 'Codepe Corretora de Valores e Câmbio S.A.'
        },
        {
          id: 164,
          codigo: '163',
          descricao: 'Commerzbank Brasil S.A. - Banco Múltiplo'
        },
        {
          id: 129,
          codigo: '090',
          descricao: 'Coop. Central de Econ. e Créd. Mútuo - Sicoob Unimais'
        },
        {
          id: 196,
          codigo: '097',
          descricao: 'Cooperativa Central de Crédito Noroeste Brasileiro Ltda'
        },
        {
          id: 116,
          codigo: '085',
          descricao: 'Cooperativa Central de Crédito Urbano-CECRED'
        },
        {
          id: 121,
          codigo: '099',
          descricao:
            'Cooperativa Central de Economia e Credito Mutuo das Unicreds'
        },
        {
          id: 124,
          codigo: '089',
          descricao: 'Cooperativa de Crédito Rural da Região da Mogiana'
        },
        {
          id: 132,
          codigo: '098',
          descricao: 'Credialiança Cooperativa de Crédito Rural'
        },
        {
          id: 102,
          codigo: '487',
          descricao: 'Deutsche Bank S.A. - Banco Alemão'
        },
        {
          id: 103,
          codigo: '064',
          descricao: 'Goldman Sachs do Brasil Banco Múltiplo S.A.'
        },
        {
          id: 104,
          codigo: '078',
          descricao: 'Haitong Banco de Investimento do Brasil S.A.'
        },
        { id: 105, codigo: '062', descricao: 'Hipercard Banco Múltiplo S.A.' },
        {
          id: 7,
          codigo: '399',
          descricao: 'HSBC Bank Brasil S.A. - Banco Múltiplo'
        },
        {
          id: 148,
          codigo: '132',
          descricao: 'ICBC do Brasil Banco Múltiplo S.A.'
        },
        { id: 106, codigo: '492', descricao: 'ING Bank N.V.' },
        {
          id: 153,
          codigo: '139',
          descricao: 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo'
        },
        { id: 107, codigo: '652', descricao: 'Itaú Unibanco Holding S.A.' },
        { id: 3, codigo: '341', descricao: 'Itaú Unibanco S.A.' },
        {
          id: 108,
          codigo: '488',
          descricao: 'JPMorgan Chase Bank, National Association'
        },
        { id: 119, codigo: '260', descricao: 'Nu Pagamentos S.A. (Nubank)' },
        {
          id: 138,
          codigo: '111',
          descricao: 'Oliveira Trust Distr. de Títulos e Valores Mobil. S.A.'
        },
        { id: 110, codigo: '254', descricao: 'Paraná Banco S.A.' },
        {
          id: 111,
          codigo: '751',
          descricao: 'Scotiabank Brasil S.A. Banco Múltiplo'
        },
        {
          id: 185,
          codigo: '545',
          descricao: 'Senso Corretora de Câmbio e Valores Mobiliários S.A.'
        },
        { id: 114, codigo: '756', descricao: 'SICOOB' },
        {
          id: 112,
          codigo: '118',
          descricao: 'Standard Chartered Bank (Brasil) S/A–Bco Invest.'
        },
        { id: 117, codigo: '197', descricao: 'Stone Pagamentos S.A' },
        {
          id: 113,
          codigo: '129',
          descricao: 'UBS Brasil Banco de Investimento S.A.'
        },
        { id: 127, codigo: '015', descricao: 'UBS Brasil Corretora de Câmbio' },
        { id: 118, codigo: '136', descricao: 'Unicred do Brasil' },
        { id: 123, codigo: '084', descricao: 'Unicred Norte do Paraná' }
      ];
      resolve(array);
    });
  },
  GetUF: function () {
    return new Promise((resolve, reject) => {
      const array = [
        {
          id: 1,
          codigoUF: 12,
          nome: 'Acre',
          uf: 'AC',
          regiao: 'Norte',
          codigoLocalizBacen: '10012'
        },
        {
          id: 2,
          codigoUF: 27,
          nome: 'Alagoas',
          uf: 'AL',
          regiao: 'Nordeste',
          codigoLocalizBacen: '10036'
        },
        {
          id: 3,
          codigoUF: 16,
          nome: 'Amapá',
          uf: 'AP',
          regiao: 'Norte',
          codigoLocalizBacen: '10014'
        },
        {
          id: 4,
          codigoUF: 13,
          nome: 'Amazonas',
          uf: 'AM',
          regiao: 'Norte',
          codigoLocalizBacen: '10013'
        },
        {
          id: 5,
          codigoUF: 29,
          nome: 'Bahia',
          uf: 'BA',
          regiao: 'Nordeste',
          codigoLocalizBacen: '10039'
        },
        {
          id: 6,
          codigoUF: 23,
          nome: 'Ceará',
          uf: 'CE',
          regiao: 'Nordeste',
          codigoLocalizBacen: '10032'
        },
        {
          id: 7,
          codigoUF: 53,
          nome: 'Distrito Federal',
          uf: 'DF',
          regiao: 'Centro-Oeste',
          codigoLocalizBacen: '10096'
        },
        {
          id: 8,
          codigoUF: 32,
          nome: 'Espírito Santo',
          uf: 'ES',
          regiao: 'Sudeste',
          codigoLocalizBacen: '10052'
        },
        {
          id: 9,
          codigoUF: 52,
          nome: 'Goiás',
          uf: 'GO',
          regiao: 'Centro-Oeste',
          codigoLocalizBacen: '10092'
        },
        {
          id: 10,
          codigoUF: 21,
          nome: 'Maranhão',
          uf: 'MA',
          regiao: 'Nordeste',
          codigoLocalizBacen: '10030'
        },
        {
          id: 11,
          codigoUF: 51,
          nome: 'Mato Grosso',
          uf: 'MT',
          regiao: 'Centro-Oeste',
          codigoLocalizBacen: '10090'
        },
        {
          id: 12,
          codigoUF: 50,
          nome: 'Mato Grosso do Sul',
          uf: 'MS',
          regiao: 'Centro-Oeste',
          codigoLocalizBacen: '10091'
        },
        {
          id: 13,
          codigoUF: 31,
          nome: 'Minas Gerais',
          uf: 'MG',
          regiao: 'Sudeste',
          codigoLocalizBacen: '10050'
        },
        {
          id: 14,
          codigoUF: 15,
          nome: 'Pará',
          uf: 'PA',
          regiao: 'Nordeste',
          codigoLocalizBacen: '10017'
        },
        {
          id: 15,
          codigoUF: 25,
          nome: 'Paraíba',
          uf: 'PB',
          regiao: 'Nordeste',
          codigoLocalizBacen: '10034'
        },
        {
          id: 16,
          codigoUF: 41,
          nome: 'Paraná',
          uf: 'PR',
          regiao: 'Sul',
          codigoLocalizBacen: '10073'
        },
        {
          id: 17,
          codigoUF: 26,
          nome: 'Pernambuco',
          uf: 'PE',
          regiao: 'Nordeste',
          codigoLocalizBacen: '10035'
        },
        {
          id: 18,
          codigoUF: 22,
          nome: 'Piauí',
          uf: 'PI',
          regiao: 'Nordeste',
          codigoLocalizBacen: '10031'
        },
        {
          id: 19,
          codigoUF: 33,
          nome: 'Rio de Janeiro',
          uf: 'RJ',
          regiao: 'Sudeste',
          codigoLocalizBacen: '10054'
        },
        {
          id: 20,
          codigoUF: 24,
          nome: 'Rio Grande do Norte',
          uf: 'RN',
          regiao: 'Nordeste',
          codigoLocalizBacen: '10033'
        },
        {
          id: 21,
          codigoUF: 43,
          nome: 'Rio Grande do Sul',
          uf: 'RS',
          regiao: 'Sul',
          codigoLocalizBacen: '10077'
        },
        {
          id: 22,
          codigoUF: 11,
          nome: 'Rondônia',
          uf: 'RO',
          regiao: 'Norte',
          codigoLocalizBacen: '10093'
        },
        {
          id: 23,
          codigoUF: 14,
          nome: 'Roraima',
          uf: 'RR',
          regiao: 'Norte',
          codigoLocalizBacen: '10018'
        },
        {
          id: 24,
          codigoUF: 42,
          nome: 'Santa Catarina',
          uf: 'SC',
          regiao: 'Sul',
          codigoLocalizBacen: '10075'
        },
        {
          id: 25,
          codigoUF: 35,
          nome: 'São Paulo',
          uf: 'SP',
          regiao: 'Sudeste',
          codigoLocalizBacen: '10058'
        },
        {
          id: 26,
          codigoUF: 28,
          nome: 'Sergipe',
          uf: 'SE',
          regiao: 'Nordeste',
          codigoLocalizBacen: '10038'
        },
        {
          id: 27,
          codigoUF: 17,
          nome: 'Tocantins',
          uf: 'TO',
          regiao: 'Norte',
          codigoLocalizBacen: '10094'
        }
      ];

      resolve(array);
    });
  },
  GetTipoConta: function () {
    return new Promise((resolve, reject) => {
      const array = [{ id: 2, descricao: 'Conta Corrente' }];
      resolve(array);
    });
  }
});
