import api from './utilPartner';

const controller = 'PropostaDocumento';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  ListarPorProposta: function (dto) {
    return api.Axios.GetParams(`${url}/ListarPorProposta`, dto);
  },
  ListarDocumentoPorProposta: function (dto) {
    return api.Axios.GetParams(url + '/ListarDocumentoPorProposta', dto);
  },
  UploadDocumentoProposta: function (dto) {
    return api.Axios.PostForm(url + '/UploadDocumentoProposta', dto);
  }
});
