import Vue from 'vue';
import App from './App.vue';
import core from '@bmp-vue/core';
import DatePicker from '../src/components/Datepicker.vue';

Vue.config.productionTip = false;

var controllers = require.context('@/controllers', true, /\.js$/);
const paths = require('@/plugins/router/paths').default;
const theme = require('@/plugins/vuetify/theme').default;

Vue.use(core, {
  controllers,
  paths,
  theme,
  defaultViews: false,
  carregaEnums: false,
  mount: (options) => {
    Vue.component('DatePicker', DatePicker);

    new Vue({
      ...options,
      render: h => h(App)
    }).$mount('#app');
  }
});

Vue.directive('uppercase', {
  bind(el, _, vnode) {
    el.addEventListener('input', e => {
      e.target.value = e.target.value.toUpperCase();
      vnode.componentInstance.$emit('input', e.target.value.toUpperCase());
    });
  }
});