import api from './utilPartner';

const controller = 'CCBDigitalAssinatura';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Get: function (dto) {
    return api.Axios.GetParams(url, dto);
  },
  ListarAssinatura: function(dto) {
    return api.Axios.GetParams(url + '/ListarAssinaturas', dto);
  },
  GerarToken: dto => {
    return api.Axios.Post(url + '/GerarToken', dto, {});
  },
  SolicitarAssinatura: dto => {
    return api.Axios.Post(url + '/SolicitarAssinatura', dto, {});
  },
  CriarSenha: (id, dto) => {
    return api.Axios.Put(url + '/CriarSenha', id, dto);
  },
  Assinar: (id, dto) => {
    return api.Axios.Put(url + '/Assinar', id, dto);
  },
  GerarCCB: (id, dto) => {
    return api.Axios.Put(url + '/GerarCCB', id, dto);
  },
  ConsultaTelefoneBlackList: (dto) => {
    return api.Axios.GetParams(url + '/ConsultaTelefoneBlackList', dto);
  },
  BuscarModeloTokenAssinatura: (dto) => {
    return api.Axios.GetParams(url + '/BuscarModeloTokenAssinatura', dto);
  },
  BuscarModeloNotificacaoAssinatura: (dto) => {
    return api.Axios.GetParams(url + '/BuscarModeloNotificacaoAssinatura', dto);
  },
  Notificar: (dto) => {
    return api.Axios.Post(url + '/Notificar', dto);
  },
  EditarAssinanteCCB: function(dto) {
    return api.Axios.Post(url + '/EditarAssinanteCCB', dto);
  }
});
