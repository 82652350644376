export default {
  GetDate(days = 0, months = 0, years = 0) {
    const date = new Date();

    date.setDate(date.getDate() + days);
    date.setMonth(date.getMonth() + months);
    date.setYear(date.getFullYear() + years);

    return date;
  },
  FormatDate(date) {
    const diaDate = date.getDate().toString().padStart(2, 0);
    const mesDate = (date.getMonth() + 1).toString().padStart(2, 0); //+1 Os Meses começão com 0.
    const anoDate = date.getFullYear().toString();

    return diaDate + '/' + mesDate + '/' + anoDate;
  },
  ValidaDatas(inicial, final) {
    const dataInicio = new Date(inicial);
    const dataFim = new Date(final);

    return dataInicio <= dataFim;
  },
  ValidaDDMMYYYY(data) {
    if (!data) return true;
    return /(((0[1-9]|[12][0-9]|3[01])([/])(0[13578]|10|12)([/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([/])(0[469]|11)([/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([/])(02)([/])(\d{4}))|((29)(\/)(02)([/])([02468][048]00))|((29)([/])(02)([/])([13579][26]00))|((29)([/])(02)([/])([0-9][0-9][0][48]))|((29)([/])(02)([/])([0-9][0-9][2468][048]))|((29)([/])(02)([/])([0-9][0-9][13579][26])))/.test(
      data
    );
  },
  ValidaLimiteDias(inicial, final, { limiteDias = 0 }) {
    const dataInicio = new Date(inicial);
    const dataFim = new Date(final);
    const diferencaTempo = dataFim.getTime() - dataInicio.getTime();
    const diferencaDias = diferencaTempo / (1000 * 3600 * 24);

    return diferencaDias <= limiteDias;
  },
  GetTimeNow() {
    const newDate = new Date();

    const secondsTime = newDate.getSeconds().toString().padStart(2, 0);
    const minutesTime = newDate.getMinutes().toString().padStart(2, 0);
    const hoursTime = newDate.getHours().toString().padStart(2, 0);

    return [hoursTime, minutesTime, secondsTime].join(':');
  },
  ChangeTime(dateTime, isLate) {
    return dateTime.slice(0, 10) + (isLate ? 'T23:59:59' : 'T00:00:00');
  }
};
