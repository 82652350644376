import api from './utilPartner';

const controller = 'AgendaRecebivel';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: function (dto) {
    return api.Axios.GetParams(url + '/Listar', dto);
  },
  Localizar: function (dto) {
    return api.Axios.GetParams(url + '/Localizar', dto);
  },
  ListarExtratoParcela: function (dto) {
    return api.Axios.GetParams(url + '/ListarExtratoParcela', dto);
  },
  ListarBoletos: function (dto) {
    return api.Axios.GetParams(url + '/ListarBoletos', dto);
  },
  CalcularValorParcelaAtual: function (dto) {
    return api.Axios.GetParams(url + '/LocalizarExtratoParcelaDetalhes', dto);
  },
  ListarDetalhesBoleto: function (dto) {
    return api.Axios.GetParams(url + '/ListarDetalhesBoleto', dto);
  },
  ConsultarBoleto: function (dto) {
    return api.Axios.GetParams(url + '/ConsultarBoleto', dto);
  },
  ListarLogsCallback: function (dto) {
    return api.Axios.GetParams(url + '/ListarLogsCallback', dto);
  },
  CalculaParcelasAnaliseProposta: function (dto) {
    return api.Axios.GetParams(url + '/CalculaParcelasAnaliseProposta', dto);
  },
  LocalizarExtratoParcelaDetalhes: function (dto) {
    return api.Axios.GetParams(url + '/LocalizarExtratoParcelaDetalhes', dto);
  }
});
