import api from '../utilPartner';

const controller = 'ImportacaoPessoa';
const url = process.env.VUE_APP_PERSON_API + controller;

export default {
  controller,
  Localizar: codigo => {
    return api.Axios.GetParams(`${url}/${codigo}`);
  },
  Listar: dto => {
    return api.Axios.GetParams(url, dto);
  },
  ImportacaoPessoaResumo: dto => {
    return api.Axios.GetParams(`${url}/ImportacaoPessoaResumo`, dto);
  },
  Importar: dto => {
    return api.Axios.PostForm(`${url}/Importar`, dto);
  },
  ResumoArquivo: dto => {
    return api.Axios.PostForm(`${url}/Arquivo/Resumo`, dto);
  },
  Reprocessar: (dto, codigo) => {
    return api.Axios.Put(url, codigo, dto);
  },
  Criar: (dto) => {
    return api.Axios.Post(url, dto);
  },
  Deletar: codigo => {
    return api.Axios.Delete(url, codigo);
  },
  DeletarTodos: () => {
    return api.Axios.Delete(`${url}/Todos`, '');
  },
  Download: (modelo) => {
    const csvPath = process.env.VUE_APP_PERSON_API + 'Static/' + modelo;

    return api.Axios.GetParams(csvPath);
  },
};
