<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    full-width
    max-width="290px"
    min-width="290px"
    data-cy="menu-date">
    <template #activator="{ on, attrs }">
      <v-text-field
        ref="input"
        v-model="dateInput"
        v-mask="'##/##/####'"
        :label="labelDefault"
        :data-cy="$attrs.label"
        outlined
        dense
        prepend-inner-icon="event"
        v-bind="attrs"
        :rules="rules"
        v-on="on" />
    </template>
    <v-date-picker
      v-model="datePicker"
      no-title
      :min="min"
      :max="max"
      @input="menu = false" />
  </v-menu>
</template>

<script>
  import DateTime from '../views/util/dateTime';

  export default {
    props: {
      value: { type: String, default: null },
      min: { type: String, default: null },
      max: { type: String, default: null }
    },
    emits: ['input'],
    data () {
      return {
        menu: false,
        datePicker: '',
        dateInput: ''
      };
    },
    computed: {
      date: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit('input', value);
        }
      },
      labelDefault: function () {
        return (
          this.$attrs.label ? this.$attrs.label +
            (this.$attrs.required === '' || this.$attrs.required === true ? ' *' : '')
          : ''
        );
      },
      rules() {
        const errors = [];
        const name = this.$attrs.name || this.$attrs.label || '';
        if (this.$attrs.required === '' || this.$attrs.required === true) {
          !!this.dateInput || errors.push(name + ' é obrigatório.');
        }

        if (this.dateInput) {
          DateTime.ValidaDDMMYYYY(this.dateInput) || errors.push(name + ' informado é inválido.');
        }

        return errors;
      }
    },
    watch: {
      datePicker (value) {
        this.dateInput = value.split('-').reverse().join('/');
      },
      dateInput (value) {
        if (!value) return;

        if (DateTime.ValidaDDMMYYYY(value)) {
          var [dia, mes, ano] = value.split('/');
          this.date = new Date(ano, Number(mes - 1), dia).toJSON();
        }
        else
          this.date = null;

        const pos = this.$refs.input.$refs.input.selectionStart;
        this.$nextTick(() => {
          if(value.length !== pos)
            this.$refs.input.$refs.input.selectionEnd = pos;
        });
      },
      date (value) {
        this.formatDate(value);
      }
    },
    mounted() {
      this.formatDate(this.date);
    },
    methods: {
      formatDate(value) {
        var formatedDate = DateTime.FormatDate(new Date(value));
        if (value && formatedDate !== this.dateInput)
          this.dateInput = formatedDate;
      }
    }
  };
</script>