<template>
  <div id="app" autocomplete="chrome-off">
    <!--Aqui irá o sitemap-->

    <v-app>
      <core-toolbar id="showLayoutBMPHeader">
        <template #toolbar-avatar>
          <v-avatar
            style="cursor: pointer"
            max-height="60px"
            size="240"
            class="mt-2 ml-3"
            tile
            @click="gotoHome">
            <v-img
              src="~shared/images/svg/logo-black.svg"
              width="100%"
              height="50%"
              contain />
          </v-avatar>
        </template>
        <template #toolbar-title>
                  &nbsp;
        </template>
        <template #toolbar-drawer>
                  &nbsp;
        </template>
        <template #toolbar-timer>
                  &nbsp;
        </template>
        <template #toolbar-options>
                  &nbsp;
        </template>
      </core-toolbar>

      <v-main>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <router-view />
            </v-col>
          </v-row>
        </v-container>
      </v-main>

      <slot id="showLayoutBMPFooter" name="footer">
        <v-footer inset app>
          <v-row class="justify-center">
            <span class="px-4">&copy; {{ new Date().getFullYear() }} - Money Plus</span>
          </v-row>
        </v-footer>
      </slot>
    </v-app>
  </div>
</template>

<script>
  import 'shared/styles/variables.scss';

  export default {
    metaInfo: {
      title: 'Money Plus',
      titleTemplate: '%s - Admin'
    },
    name: 'App',
    data () {
      return {
        isLoaded: false,
      };
    },
    computed: {
      loading () {
        return this.$store.getters.loading || this.$store.getters.activeRequests > 0;
      },
      drawer: function () {
        return this.$store.getters.drawer;
      }
    },
    mounted () {
      var vm = this;

      vm.$store.commit('isMobile');
      if (!vm.$store.getters.isMobile && !vm.$store.getters.drawer) { vm.$store.commit('drawer'); }

      window.addEventListener('resize', function () {
        vm.$store.commit('isMobile');
      });
    },
    methods: {
      gotoHome () {
        const path = this.$store.getters.initialRoute;
        if (this.$route.path !== path) this.$router.push(path);
      }
    }
  };
</script>

<style>
  .v-navigation-drawer--mini-variant, .v-navigation-drawer {
    overflow: visible !important;
  }
  p {
    word-break: break-word;
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.03125em !important;
    font-family: "Roboto", sans-serif !important;
  }
  h1 + p {
    font-size: 1.25rem;
    font-weight: 300;
  }
  .fulltechpro-props-item-code code {
    background-color: transparent !important;
  }

</style>