import api from './utilPartner';

const controller = 'PessoaPF';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  LocalizarRegistro: function (dto) {
    return api.Axios.GetParams(url + '/LocalizarRegistro', dto);
  },
  RendaTotal: function (dto) {
    return api.Axios.GetParams(url + '/RendaTotal', dto);
  }
});
