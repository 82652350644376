import api from './utilPartner';

const controller = 'TipoContrato';
const url = process.env.VUE_APP_ROOT_API + controller;

export default Object.assign({}, api.GetCrud(controller, null), {
    Listar: function (dto) {
        return api.Axios.GetParams(url, dto);
    },
    ListarTipoContratoPorEmpresa: function (dto) {
      return api.Axios.GetParams(url + '/ListarTipoContratoPorEmpresa', dto);
    }
  });