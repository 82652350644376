import api from './utilPartner';

const controller = 'PropostaContaPagamento';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  ListarPorProposta: function (dto) {
    return api.Axios.GetParams(
      `${url}/ListarReferenciaBancariaPorProposta`,
      dto
    );
  }
});
