import api from './utilPartner';

const controller = 'SmartSearch';
const url = process.env.VUE_APP_ROOT_API + controller;

export default {
  controller,
  Count(search) {
    return api.Axios.GetParams(`${url}/${search}`);
  },
  ListarPropostas(search) {
    return api.Axios.GetParams(`${url}/Propostas/${search}`);
  },
  ListarAnalises(search) {
    return api.Axios.GetParams(`${url}/Analises/${search}`);
  },
  ListarPessoas(search) {
    return api.Axios.GetParams(`${url}/Pessoas/${search}`);
  }
};
