import api from './utilPartner';

const controller = 'AlteracaoCadastral';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: function (dto) {
    return api.Axios.GetParams(url, dto);
  },
  ListarHistorico: function (dto) {
    return api.Axios.GetParams(url + '/Historico', dto);
  },
  ListarPorEntidade: function (dto) {
    return api.Axios.GetParams(url + '/ListarPorEntidade', dto);
  },
  Aprovar: function (dto) {
    return api.Axios.Post(`${url}/Aprovar`, dto);
  },
  Recusar: function (dto) {
    return api.Axios.Post(`${url}/Recusar`, dto);
  },
});
