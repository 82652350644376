import util from '@bmp-vue/core/api/util';
import api from './utilPartner';
import axios from 'axios';
import { gerarIdempotencyKey } from '@/utils/idemPotencyKey';

const controller = 'Autenticacao';
const url = process.env.VUE_APP_ROOT_API + controller + '/';

export default Object.assign({}, util.GetCrud(controller, null), {
  Login(dto) {
    const idempotencyKey = gerarIdempotencyKey();
    return axios.post(url + 'Login', dto, {
      headers: { idempotencyKey }
    });
  },
  RefreshPartner(dto, token) {
    const idempotencyKey = gerarIdempotencyKey();
    return axios
      .post(url + 'Refresh', dto, {
        headers: {
          idempotencyKey,
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        response.data.expires = response.data.expiresIn;
        return response;
      });
  },
  Logout() {
    return new Promise(resolve => {
      resolve('');
    });
  },
  RefreshToken() {
    const { refreshToken, token } = JSON.parse(localStorage.getItem('user'));
    const empresa = JSON.parse(localStorage.getItem('empresa'));
    
    const dto = {
      token: refreshToken,
      codigoEmpresa: empresa.codigo
    };

    return this.RefreshPartner(dto, token)
      .then(({ data }) => {
        return data;
      })
      .catch(() => this.Logout());
  },
  ResetSenha(dto) {
    const idempotencyKey = gerarIdempotencyKey();
    return axios.post(url + 'ResetSenha', dto, {
      headers: { idempotencyKey }
    });
  },
  ResetSenhaAdmin(dto) {
    return api.Axios.Post(url + 'ResetSenhaAdmin', dto);
  },
  ValidarTokenResetSenha(dto) {
    const idempotencyKey = gerarIdempotencyKey();
    return axios.post(url + 'ValidarTokenResetSenha', dto, {
      headers: { idempotencyKey }
    });
  },
  SolicitarTokenResetSenha(dto) {
    dto.isPartner = true;
    const idempotencyKey = gerarIdempotencyKey();
    return axios.post(url + 'SolicitarTokenResetSenha', dto, {
      headers: { idempotencyKey }
    });
  },
});
