import api from './utilPartner';

const controller = 'PessoaDocumento';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  ListarPorPessoa: function (dto) {
    return api.Axios.GetParams(url + '/ListarPorPessoa', dto);
  },
  UploadDocumentoPessoa: function (dto) {
    return api.Axios.PostForm(url + '/UploadDocumentoPessoa', dto);
  },
  DownloadDocumentoPessoa: function (dto) {
    return api.Axios.GetParams(url + '/DownloadDocumentoPessoa', dto);
  }
});
