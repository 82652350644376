import api from './utilPartner';

const controller = 'SolicitacaoAnalise';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: function (dto) {
    return api.Axios.GetParams(url + '/Listar', dto);
  },
  LocalizarRegistro: function (dto) {
    return api.Axios.GetParams(url + '/LocalizarRegistro', dto);
  }
});
